const GetPresignedUrl = async (fileName, contentType) => {
  // Get presigned URL
  try {
    const response = await fetch(
      new Request(
        `${process.env.REACT_APP_LAMBDAS_URL}uploadFileUrl?${new URLSearchParams({
          filename: fileName,
          contenttype: contentType
        })}`,
        {
          method: "GET"
        }
      )
    );

    const content = await response.json();
    return content;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data);
    }

    throw new Error("Unknown network error");
  }
};

export default GetPresignedUrl;
