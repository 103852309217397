import React, { useState } from "react";
import PropTypes from "prop-types";
import ErrorMessages from "../ErrorMessages/ErrorMessages";
import "./TextInput.css";

const TextInput = (props) => {
  const { text, label, placeholder, disabled, validators, fieldName, onChangeText } = props;

  const [inputText, setInputText] = useState(text);
  const [errors, setErrors] = useState([]);

  const validate = () => {
    const errorMessages = [];

    if (validators && validators.length > 0) {
      validators.forEach((validator) => {
        errorMessages.push({ message: validator(text, fieldName) });
      });
    }

    setErrors(errorMessages);
  };

  React.useEffect(() => {
    setInputText(text);
  }, [text]);

  const onChange = (event) => {
    if (!disabled && onChangeText) {
      const { value } = event.target;

      onChangeText(value);
      setInputText(value);
    }
  };

  return (
    <div className="FieldGroup">
      <label htmlFor={fieldName}>
        <p className="Label">{label}</p>
        <input
          className="TextInput"
          id={fieldName}
          name={fieldName}
          type="text"
          placeholder={placeholder}
          value={inputText}
          onChange={onChange}
          onBlur={validate}
          disabled={disabled}
          error={(errors.length > 0).toString()}
        />
        {errors.length > 0 && <ErrorMessages errors={errors} />}
      </label>
    </div>
  );
};

TextInput.propTypes = {
  onChangeText: PropTypes.func,
  text: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  validators: PropTypes.arrayOf(PropTypes.func),
  fieldName: PropTypes.string.isRequired
};

TextInput.defaultProps = {
  onChangeText: () => {},
  label: "",
  text: "",
  placeholder: "",
  disabled: false,
  validators: []
};

export default TextInput;
