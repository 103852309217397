import React from "react";
import { v4 as uuidv4 } from "uuid";
import "./App.css";
import ImageUploadPage from "./pages/ImageUploadPage/ImageUploadPage";
import CustomerForm from "./pages/CustomerForm/CustomerForm";
import Checkout from "./pages/Checkout/Checkout";
import logo from "./images/logo.png";

function App() {
  const [uuid] = React.useState(localStorage.getItem("uuidv4Token") || uuidv4());
  const [step, setStep] = React.useState(1);

  React.useEffect(() => {
    localStorage.setItem("uuidv4Token", uuid);
  }, [uuid]);

  return (
    <div>
      <header className="appHeader">
        <div className="container">
          <img src={logo} alt="Logo" width="128" height="28" />
        </div>
      </header>

      <ul className="steps">
        <li className={step === 1 ? "selected" : "step"}>
          <span
            aria-hidden="true"
            onClick={() => {
              setStep(1);
            }}
          >
            Upload Image
          </span>
        </li>
        <li className={step === 2 ? "selected" : "step"}>
          <span
            aria-hidden="true"
            onClick={() => {
              if (step >= 2) {
                setStep(2);
              }
            }}
          >
            Delivery Information
          </span>
        </li>
        <li className={step === 3 ? "selected" : "step"}>Payment</li>
      </ul>
      {step >= 1 ? (
        <ImageUploadPage
          visible={step === 1}
          onComplete={() => {
            setStep(2);
          }}
        />
      ) : null}
      {step >= 2 ? (
        <CustomerForm
          visible={step === 2}
          onComplete={() => {
            setStep(3);
          }}
        />
      ) : null}
      {step === 3 ? <Checkout /> : null}
    </div>
  );
}

export default App;
