import React from "react";
import PropTypes from "prop-types";
import Joi from "joi";
import TextInput from "../../components/TextInput/TextInput";
import ErrorMessages from "../../components/ErrorMessages/ErrorMessages";
import PostcodeLookup from "../../api/PostcodeLookup";
import { required } from "../../components/TextInput/Validators";

const postcodeScehma = Joi.string()
  .pattern(/^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i, { name: "postcode" })
  .trim()
  .required();

const schema = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  streetAddress: Joi.string().required(),
  postcode: postcodeScehma,
  district: Joi.string().required(),
  town: Joi.string().min(3).required()
});

const postcodeSchema = Joi.object({
  postcode: postcodeScehma
});

const CustomerForm = (props) => {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [streetAddress, setStreetAddress] = React.useState("");
  const [addressLine2, setAddressLine2] = React.useState("");
  const [postcode, setPostcode] = React.useState("");
  const [district, setDistrict] = React.useState("");
  const [town, setTown] = React.useState("");
  const [formErrors, setFormErrors] = React.useState([]);
  const { onComplete, visible } = props;

  const checkPostcode = async () => {
    // Validate through SoPost postcode API endpoint
    setFormErrors([]);

    try {
      const value = await postcodeSchema.validateAsync({
        postcode
      });

      // Set localstorage from value
      const result = await PostcodeLookup(value.postcode);

      if (result.status === 200) {
        // Set district and town
        setDistrict(result.body.district);
        setTown(result.body.town);
      } else {
        setFormErrors([{ message: result.body }]);
      }
    } catch (err) {
      setFormErrors([err]);
    }
  };

  const submitForm = async () => {
    setFormErrors([]);
    try {
      // eslint-disable-next-line no-unused-vars
      const value = await schema.validateAsync({
        firstName,
        lastName,
        email,
        streetAddress,
        postcode,
        district,
        town
      });

      // Set localstorage from value
      localStorage.setItem("firstName", firstName);
      localStorage.setItem("lastName", lastName);
      localStorage.setItem("email", email);
      localStorage.setItem("line1", streetAddress);
      localStorage.setItem("line2", addressLine2);
      localStorage.setItem("postcode", postcode);
      localStorage.setItem("district", district);
      localStorage.setItem("town", town);

      onComplete();
    } catch (err) {
      setFormErrors([err]);
    }
  };

  return (
    <div style={{ display: visible ? "block" : "none" }}>
      <div className="containerSmall">
        <h2>Enter your delivery information</h2>
        <p>
          Let us know where to send your postcard to. Please note that we can only send to UK
          addresses.
        </p>
      </div>
      <form className="customerForm">
        <div className="fieldRow">
          <TextInput
            onChangeText={(text) => {
              setFirstName(text);
            }}
            text={firstName}
            fieldName="firstName"
            label="First Name"
            validators={[required]}
          />

          <TextInput
            onChangeText={(text) => {
              setLastName(text);
            }}
            text={lastName}
            fieldName="lastName"
            label="Last Name"
            validators={[required]}
          />
        </div>

        <div className="fieldRow">
          <TextInput
            onChangeText={(text) => {
              setEmail(text);
            }}
            text={email}
            fieldName="email"
            label="Email"
            validators={[required]}
          />
        </div>

        <div className="fieldRow">
          <TextInput
            onChangeText={(text) => {
              setStreetAddress(text);
            }}
            text={streetAddress}
            fieldName="address"
            label="Address Line 1"
            validators={[required]}
          />

          <TextInput
            onChangeText={(text) => {
              setAddressLine2(text);
            }}
            text={addressLine2}
            label="Address Line 2"
            fieldName="addressLine2"
          />
        </div>

        <div className="fieldRow" style={{ alignItems: "flex-start" }}>
          <TextInput
            onChangeText={(text) => {
              setPostcode(text);
            }}
            text={postcode}
            fieldName="postcode"
            label="Postcode"
            placeholder="Type your postcode"
            validators={[required]}
          />
          <button
            style={{ width: 150, marginTop: 25 }}
            className="button"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              checkPostcode();
            }}
          >
            Find Address
          </button>
        </div>

        <div className="fieldRow">
          <TextInput
            key="input-town"
            text={town}
            fieldName="town"
            label="Town"
            validators={[required]}
            disabled
          />

          <TextInput
            key="input-district"
            text={district}
            label="District"
            fieldName="district"
            validators={[required]}
            disabled
          />
        </div>

        {formErrors.length > 0 && <ErrorMessages errors={formErrors} />}
      </form>

      <button
        className="button"
        style={{ margin: "50px auto", display: "block" }}
        type="submit"
        onClick={(e) => {
          e.preventDefault();
          submitForm();
        }}
      >
        Continue to Payment
      </button>
    </div>
  );
};

CustomerForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired
};

export default CustomerForm;
