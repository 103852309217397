import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, useStripe, Elements, useElements } from "@stripe/react-stripe-js";
import GetPublicStripeKey from "../../api/GetPublicStripeKey";
import CreatePaymentIntent from "../../api/CreatePaymentIntent";

const options = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

const CheckoutForm = () => {
  const [clientSecret, setClientSecret] = React.useState(null);
  const [processing, setProcessing] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [succeeded, setSucceeded] = React.useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const setStripeKey = async () => {
    const response = await CreatePaymentIntent();
    setClientSecret(response.body);
  };

  React.useEffect(() => {
    setStripeKey();
  }, []);

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    // Step 3: Use clientSecret from PaymentIntent and the CardElement
    // to confirm payment with stripe.confirmCardPayment()
    // TODO: Split this logic off, it's a bit bulky for a component
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${localStorage.getItem("firstName")} ${localStorage.getItem("lastName")}`,
          email: localStorage.getItem("email")
        },
        metadata: {
          uuid: localStorage.getItem("uuid"),
          file: localStorage.getItem("file")
        }
      },
      shipping: {
        address: {
          line1: localStorage.getItem("line1"),
          line2: localStorage.getItem("line2"),
          city: localStorage.getItem("town"),
          state: localStorage.getItem("district"),
          postal_code: localStorage.getItem("postcode")
        },
        name: `${localStorage.getItem("firstName")} ${localStorage.getItem("lastName")}`
      }
    });

    if (payload.error) {
      setError(`Payment failed: ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setSucceeded(true);
      setProcessing(false);

      localStorage.clear();
    }
  };

  const RenderSuccess = () => {
    return (
      <div className="customerForm">
        <h1>Your order has been submitted!</h1>
        <p>Thank you for ordering a SoPostcard. Your postcard will be with you soon.</p>
      </div>
    );
  };

  if (succeeded) {
    return <RenderSuccess />;
  }

  return (
    <form onSubmit={handleSubmit} className="customerForm">
      <p>
        Please provide your billing details. Note that whilst the payment integration is real, you
        can use dummy details as it is in test mode. Enter &apos;4242 4242 4242 4242&apos; as the
        card number and &apos;12345&apos; as the postcode
      </p>
      <CardElement className="sr-input sr-card-element" options={options} />

      {error && <div className="message sr-field-error">{error}</div>}

      <button type="submit" className="button" disabled={processing || !clientSecret || !stripe}>
        {processing ? "Processing…" : "Pay"}
      </button>
    </form>
  );
};

const Checkout = (props) => {
  const [stripePromise, setStripePromise] = React.useState(null);

  const setStripeKey = async () => {
    const stripeKey = await GetPublicStripeKey();
    setStripePromise(loadStripe(stripeKey.body));
  };

  React.useEffect(() => {
    setStripeKey();
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default Checkout;
