/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import ImageDropzone from "../../components/ImageDropzone/ImageDropzone";
import UploadImage from "../../api/UploadImage";
import ErrorMessages from "../../components/ErrorMessages/ErrorMessages";

const ImageUploadPage = (props) => {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [uploadProcessing, setUploadProcessing] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const { visible, onComplete } = props;

  const onDrop = async (acceptedFiles) => {
    setUploadProcessing(true);
    setSuccess(false);
    const result = await UploadImage(acceptedFiles);

    if (result.status === 200) {
      setUploadProcessing(false);
      setSuccess(true);
      // Show success
    } else {
      setUploadProcessing(false);
      // Show error
      setErrorMessage({ message: result.body });
    }
  };

  return (
    <div style={{ display: visible ? "block" : "none" }}>
      <div className="containerSmall">
        <h2>Postcard Template</h2>
        <p>
          The postcard size should be A5 (210 mm x 148 mm or 8.3 x 5.8 inches) with a 5 mm bleed and
          no border. If you&apos;re using an image the size should be: w: 2480 x h: 1748 px,
          density: 300 dpi. The file should be saved as a PNG.
        </p>
      </div>
      <ImageDropzone onDropFunc={onDrop} disabled={uploadProcessing} />
      {errorMessage ? <ErrorMessages errors={[errorMessage]} /> : null}
      {uploadProcessing ? <p>Upload is currently processing</p> : null}
      {success ? (
        <div className="containerSmall">
          <p>
            If you would like to replace the image, drag and drop another file to replace it or
            browse files to upload.
          </p>
          <button type="button" className="button" onClick={() => onComplete()}>
            Continue to Delivery Information
          </button>
        </div>
      ) : null}
    </div>
  );
};

ImageUploadPage.propTypes = {
  visible: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired
};

export default ImageUploadPage;
