const GetPublicStripeKey = async (acceptedFile) => {
  try {
    const response = await fetch(
      new Request(`${process.env.REACT_APP_LAMBDAS_URL}stripePublicKey`, {
        method: "GET"
      })
    );

    const content = await response.json();

    return {
      status: response.status,
      body: content
    };
  } catch (err) {
    if (err.response) {
      return {
        status: err.response.status,
        headers: err.response.headers,
        body: err.response.data
      };
    }

    return {
      status: 500,
      headers: {},
      body: "Unknown network error"
    };
  }
};

export default GetPublicStripeKey;
