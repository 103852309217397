import GetPresignedUrl from "./GetPresignedUrl";

const handleErr = (err) => {
  if (err.response) {
    return {
      status: err.response.status,
      headers: err.response.headers,
      body: err.response.data
    };
  }

  return {
    status: 500,
    headers: {},
    body: "Unknown network error"
  };
};

const UploadImage = async (acceptedFile) => {
  const extension = acceptedFile.name.split(".").pop();
  const name = localStorage.getItem("uuidv4Token");
  const fileName = `${name}.${extension}`;

  const file = new File([acceptedFile], fileName, {
    type: acceptedFile.type,
    lastModified: acceptedFile.lastModified
  });

  // Get presigned URL
  try {
    const presignedUrlResponse = await GetPresignedUrl(fileName, acceptedFile.type);

    // If successful, try upload
    if (presignedUrlResponse.presignedUrl !== undefined) {
      try {
        const response = await fetch(presignedUrlResponse.presignedUrl, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": acceptedFile.type
          }
        });

        localStorage.setItem("file", fileName);

        return {
          status: response.status
        };
      } catch (err) {
        return handleErr(err);
      }
    } else {
      throw new Error("Cannot upload image.");
    }
  } catch (err) {
    return handleErr(err);
  }
};

export default UploadImage;
