/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";
import uploadIcon from "../../images/upload-icon.png";
import "./ImageDropzone.css";

const ImageDropzone = (props) => {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [image, setImage] = React.useState(null);
  const { disabled, onDropFunc } = props;

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length) {
        // Display file in the image upload zone if it is the correct type
        setErrorMessage("");

        const reader = new FileReader();

        reader.onload = () => {
          setImage(reader.result);
        };

        reader.readAsDataURL(acceptedFiles[0]);

        onDropFunc(acceptedFiles[0]);
      } else {
        setErrorMessage("Please upload a valid file.");
      }
    },
    [onDropFunc]
  );

  return (
    <div>
      <Dropzone onDrop={onDrop} accept={["image/png"]} maxSize={20 * 1048576}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: "Container", disabled })}>
            <input {...getInputProps()} />
            <div>
              {!image ? (
                <div>
                  <img src={uploadIcon} alt="Upload" width="102" />
                  <h3>
                    {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                    Drag and drop or <strong className="anchor">browse</strong> files to upload
                  </h3>
                  <p>We support the following formats: PNG</p>
                </div>
              ) : (
                <img src={image} alt="Upload" />
              )}
            </div>
          </div>
        )}
      </Dropzone>
      {errorMessage}
    </div>
  );
};

ImageDropzone.propTypes = {
  onDropFunc: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

ImageDropzone.defaultProps = {
  disabled: true
};

export default ImageDropzone;
