import * as React from "react";
import PropTypes from "prop-types";
import "./ErrorMessages.css";

const ErrorMessages = (props) => {
  const { errors } = props;

  return (
    <div className="ErrorMessages">
      {errors.map((error, index) => (
        <div key={`errors_${index.toString()}`}>
          <p>{error.message}</p>
        </div>
      ))}
    </div>
  );
};

ErrorMessages.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ErrorMessages;
